import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X, ChevronDown } from "lucide-react";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navItems = [
    {
      title: "About",
      subitems: [
        { name: "The Mission", href: "/dev/about#mission" },
        { name: "The Event", href: "/dev/about#event" },
      ],
    },
    {
      title: "Get Involved",
      subitems: [
        { name: "Registration", href: "/get-involved/registration" },
        { name: "Fundraising", href: "/get-involved/fundraising" },
      ],
    },
    {
      title: "Support",
      subitems: [
        { name: "Sponsors", href: "/dev/support#sponsors" },
        { name: "Charities", href: "/dev/support#charities" },
      ],
    },
    {
      title: "Resources",
      subitems: [
        { name: "For Participants", href: "/dev/resources#participants" },
        { name: "For Supporters", href: "/dev/resources#supporters" },
        { name: "Travel", href: "/dev/resources#travel" },
        { name: "Accomodation", href: "/dev/resources#accomodation" },
      ],
    },
    {
      title: "News & Media",
      href: "/news",
    },
    {
      title: "Contact",
      href: "/contact",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (href) => {
    if (href.includes("#")) {
      const [path, hash] = href.split("#");
      if (window.location.pathname === path || path === "/") {
        const element = document.getElementById(hash);
        element?.scrollIntoView({ behavior: "smooth" });
      } else {
        window.location.href = href;
      }
    } else {
      window.location.href = href;
    }
    setIsMobileMenuOpen(false);
  };

  return (
    <header
      className={`fixed top-0 w-full z-50 transition-all duration-300 ${
        isScrolled
          ? "bg-backgroundDark/95 backdrop-blur-md py-4"
          : "bg-transparent py-6"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <nav className="flex items-center justify-between">
          <motion.a
            href="/"
            whileHover={{ scale: 1.05 }}
            className="flex items-center"
          >
            <img
              src="/TD4-colour.png"
              alt="Tour De 4 Logo"
              className="h-12 w-auto"
            />
          </motion.a>

          <div className="hidden lg:flex items-center space-x-8">
            {navItems.map((item) => (
              <div key={item.title} className="relative group">
                <button
                  onClick={() => item.href && handleNavigation(item.href)}
                  className="text-white/90 hover:text-white flex items-center space-x-1"
                >
                  <span>{item.title}</span>
                  {item.subitems && <ChevronDown className="w-4 h-4" />}
                </button>

                {item.subitems && (
                  <div className="absolute top-full left-0 mt-2 w-48 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200">
                    <div className="bg-backgroundLight/95 backdrop-blur-md rounded-lg shadow-xl p-2">
                      {item.subitems.map((subitem) => (
                        <button
                          key={subitem.name}
                          onClick={() => handleNavigation(subitem.href)}
                          className="block w-full text-left px-4 py-2 text-white/80 hover:text-white hover:bg-white/10 rounded-md"
                        >
                          {subitem.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="hidden lg:block px-6 py-2 rounded-full text-slate-800 font-semibold"
            style={{ background: colors.accent }}
            onClick={() => handleNavigation("/get-involved/registration")}
          >
            Register Now
          </motion.button>

          <button
            className="lg:hidden text-white"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </nav>

        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="lg:hidden"
            >
              <div className="py-4">
                {navItems.map((item) => (
                  <div key={item.title} className="py-2">
                    <button
                      onClick={() => item.href && handleNavigation(item.href)}
                      className="text-white/90 font-medium"
                    >
                      {item.title}
                    </button>
                    {item.subitems && (
                      <div className="ml-4 mt-2 space-y-2">
                        {item.subitems.map((subitem) => (
                          <button
                            key={subitem.name}
                            onClick={() => handleNavigation(subitem.href)}
                            className="block text-white/70 hover:text-white"
                          >
                            {subitem.name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="mt-4 w-full px-6 py-2 rounded-full text-slate-800 font-semibold"
                  style={{ background: colors.accent }}
                  onClick={() => handleNavigation("/get-involved/registration")}
                >
                  Register Now
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};
