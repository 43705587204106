import { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";

const ResponsiveInput = ({ value, onChange, error }) => {
  const [placeholder, setPlaceholder] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const updatePlaceholder = useCallback(() => {
    if (!isFocused) {
      if (window.innerWidth < 640) {
        setPlaceholder("Register your interest here");
      } else {
        setPlaceholder("Please enter your email to register your interest");
      }
    }
  }, [isFocused]);

  // Initial setup and resize listener
  useEffect(() => {
    updatePlaceholder();

    const handleResize = () => {
      requestAnimationFrame(updatePlaceholder);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updatePlaceholder]);

  const handleFocus = useCallback(() => {
    setPlaceholder("");
    const timeoutId = setTimeout(() => {
      setIsFocused(true);
      setPlaceholder("Enter your Email");
    }, 150);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleBlur = useCallback(() => {
    setPlaceholder("");
    const timeoutId = setTimeout(() => {
      setIsFocused(false);
      if (window.innerWidth < 640) {
        setPlaceholder("Register your interest here");
      } else {
        setPlaceholder("Enter your email to register your interest");
      }
    }, 150);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <motion.div className="flex-1">
      <motion.input
        type="email"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        initial={false}
        animate={{
          opacity: placeholder ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
        }}
        className={`w-full bg-transparent text-white 
          ${isFocused ? "placeholder-gray-400 italic" : "placeholder-white/60"}
          px-4 py-3 outline-none 
          ${error ? "border-red-500" : ""}`}
        required
        style={{
          WebkitTextFillColor: "#ffffff",
          WebkitBoxShadow: "0 0 0 30px transparent inset",
          boxShadow: "0 0 0 30px transparent inset",
          transition: "background-color 5000s ease-in-out 0s",
        }}
      />
    </motion.div>
  );
};

export default ResponsiveInput;
