import React, { useEffect } from "react";
import { motion } from "framer-motion";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const Donate = () => {
  useEffect(() => {
    window.location.href = "https://www.gofundme.com/f/tour-de-4";
  }, []);

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{
        background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center p-8"
      >
        <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
          Redirecting to GoFundMe
        </h1>
        <p className="text-white/80 text-lg mb-6">
          You are being redirected to the Tour de 4 fundraising page...
        </p>
        <div className="animate-spin w-8 h-8 border-4 border-secondary border-t-transparent rounded-full mx-auto" />
      </motion.div>
    </div>
  );
};

export default Donate;
