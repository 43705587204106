import React, { useState } from "react";
import { motion, LayoutGroup } from "framer-motion";
import { ChevronDown, Calendar, MapPin, ExternalLink } from "lucide-react";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const eventOptions = [
  {
    title: "Full Ride",
    description: "Complete the full Tour De 4 challenge",
    details: {
      distance: "160km",
      elevation: "2,500m",
      difficulty: "Challenging",
      support: "Full support stations every 40km",
      requirements:
        "Road bike required, previous cycling experience recommended",
    },
  },
  {
    title: "Half Ride",
    description: "Join us for half the distance",
    details: {
      distance: "80km",
      elevation: "1,200m",
      difficulty: "Moderate",
      support: "Support stations every 20km",
      requirements: "Any bike suitable, basic cycling experience needed",
    },
  },
  {
    title: "Family Loop",
    description: "A shorter, family-friendly route",
    details: {
      distance: "20km",
      elevation: "200m",
      difficulty: "Easy",
      support: "Continuous support vehicle",
      requirements: "Any bike welcome, suitable for all ages",
    },
  },
  {
    title: "Virtual Participation",
    description: "Join from anywhere in the world",
    details: {
      platform: "Zwift",
      options: "Multiple daily group rides",
      flexibility: "Complete at your own pace",
      community: "Join our global Discord community",
      support: "24/7 virtual support team",
    },
  },
];

const EventSection = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  return (
    <section className="bg-gray-50 py-20">
      <div className="max-w-6xl mx-auto px-6 space-y-12">
        <h2
          className="text-4xl font-bold"
          style={{ color: colors.primaryDark }}
        >
          The Event
        </h2>

        <div className="grid lg:grid-cols-3 gap-8">
          {/* Event Options */}
          <div className="lg:col-span-2 space-y-4">
            <h3
              className="text-2xl font-semibold"
              style={{ color: colors.secondary }}
            >
              Event Options
            </h3>

            <LayoutGroup>
              {eventOptions.map((option, index) => (
                <motion.div
                  key={option.title}
                  layout
                  className="rounded-lg shadow-sm overflow-hidden bg-white"
                  transition={{
                    layout: { duration: 0.2, ease: "easeOut" },
                  }}
                >
                  <motion.div
                    className="p-4 cursor-pointer"
                    onClick={() =>
                      setSelectedEvent(selectedEvent === index ? null : index)
                    }
                  >
                    <div className="flex justify-between items-center">
                      <h4 className="font-semibold text-lg">{option.title}</h4>
                      <motion.div
                        animate={{ rotate: selectedEvent === index ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <ChevronDown className="w-5 h-5 text-gray-500" />
                      </motion.div>
                    </div>
                    <p className="text-gray-600">{option.description}</p>
                  </motion.div>

                  {selectedEvent === index && (
                    <motion.div
                      layout
                      className="border-t border-gray-100 p-4 bg-white"
                    >
                      <div className="grid gap-4">
                        {Object.entries(option.details).map(([key, value]) => (
                          <div key={key} className="flex justify-between">
                            <span className="font-medium capitalize text-gray-700">
                              {key.replace(/([A-Z])/g, " $1").trim()}:
                            </span>
                            <span className="text-gray-600">{value}</span>
                          </div>
                        ))}
                        <button
                          className="mt-4 w-full px-6 py-3 rounded-full text-white font-semibold transition-transform hover:scale-[1.02] active:scale-[0.98]"
                          style={{ background: colors.secondary }}
                        >
                          Register for {option.title}
                        </button>
                      </div>
                    </motion.div>
                  )}
                </motion.div>
              ))}
            </LayoutGroup>
          </div>

          {/* Key Information */}
          <div className="space-y-6">
            <h3
              className="text-2xl font-semibold"
              style={{ color: colors.secondary }}
            >
              Key Information
            </h3>
            <div className="space-y-4">
              <div className="p-6 rounded-lg bg-white shadow-sm transition-transform duration-200 hover:-translate-y-1">
                <div className="flex items-center gap-3 mb-3">
                  <Calendar
                    className="w-5 h-5"
                    style={{ color: colors.secondary }}
                  />
                  <h4 className="font-semibold text-lg">Schedule</h4>
                </div>
                <div className="space-y-2">
                  <p className="text-gray-600">
                    Full event schedule to be announced
                  </p>
                  <p className="text-gray-500 text-sm">
                    Sign up for updates to be notified
                  </p>
                </div>
              </div>

              <div className="p-6 rounded-lg bg-white shadow-sm transition-transform duration-200 hover:-translate-y-1">
                <div className="flex items-center gap-3 mb-3">
                  <MapPin
                    className="w-5 h-5"
                    style={{ color: colors.secondary }}
                  />
                  <h4 className="font-semibold text-lg">Location</h4>
                </div>
                <div className="space-y-2">
                  <p className="text-gray-600">Details coming soon</p>
                  <a
                    href="/faq#transport"
                    className="inline-flex items-center gap-2 mt-2 text-sm font-medium transition-transform duration-200 hover:translate-x-1"
                    style={{ color: colors.secondary }}
                  >
                    View transport information
                    <ChevronDown className="w-4 h-4 rotate-[-90deg]" />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="p-4 rounded-lg"
              style={{ background: colors.primaryDark }}
            >
              <p className="text-white text-sm">
                For the latest updates and announcements about the event, follow
                us on social media or sign up for our newsletter.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default function AboutPage() {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative h-96 flex items-center justify-center"
        style={{
          background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
        }}
      >
        <div className="text-center space-y-6 p-8">
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="text-6xl font-bold text-white"
          >
            About <span style={{ color: colors.accent }}>Tour De 4</span>
          </motion.h1>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="flex justify-center gap-4"
          >
            <button
              onClick={() => scrollToSection("mission")}
              className="px-6 py-3 rounded-full text-white font-semibold transition-all hover:opacity-90"
              style={{ background: colors.secondary }}
            >
              The Mission
            </button>
            <button
              onClick={() => scrollToSection("event")}
              className="px-6 py-3 rounded-full text-slate-800 font-semibold transition-all hover:opacity-90"
              style={{ background: colors.accent }}
            >
              The Event
            </button>
          </motion.div>
        </div>
        <motion.div
          animate={{ y: [0, 10, 0] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="absolute bottom-8 text-white/60"
        >
          <ChevronDown size={32} />
        </motion.div>
      </motion.div>

      {/* Mission Section */}
      <motion.section
        id="mission"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="bg-white py-20"
      >
        <div className="max-w-6xl mx-auto px-6 space-y-12">
          <h2
            className="text-4xl font-bold"
            style={{ color: colors.primaryDark }}
          >
            The Mission
          </h2>

          <div className="space-y-8">
            <div className="prose prose-lg">
              <p className="text-lg text-gray-700">
                Following Sir Chris Hoy's diagnosis with Stage 4 cancer, Tour De
                4 was established with a clear purpose: to demonstrate that a
                Stage 4 diagnosis, while devastating, doesn't define the limits
                of what's possible.
              </p>

              <div className="my-8 p-6 rounded-lg bg-gray-50">
                <h3
                  className="text-xl font-semibold mb-4"
                  style={{ color: colors.secondary }}
                >
                  Key Statistics
                </h3>
                <ul className="space-y-2 text-gray-700">
                  <li>Over 375,000 new cancer cases in the UK yearly</li>
                  <li>Approximately 28% of cases are diagnosed at Stage 4</li>
                  <li>Survival rates have doubled in the last 40 years</li>
                </ul>
              </div>

              <p className="text-lg text-gray-700">
                Our vision is to create a supportive community that brings
                together those affected by Stage 4 cancer - patients, families,
                and supporters - while raising vital funds for research and
                support services.
              </p>
            </div>

            <motion.a
              href="https://www.justgiving.com/fundraising/tour-de-4"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 px-6 py-3 rounded-full text-white font-semibold transition-all"
              style={{ background: colors.secondary }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Make a Donation <ExternalLink size={16} />
            </motion.a>
          </div>
        </div>
      </motion.section>

      {/* Event Section */}
      <motion.section id="event">
        <EventSection />
      </motion.section>
    </div>
  );
}
