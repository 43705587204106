import { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

const CACHE_TTL = 1000 * 60 * 60; // 1 hour in milliseconds

export const useContent = () => {
  const [content, setContent] = useState(() => {
    // Check localStorage for cached content
    const cached = localStorage.getItem('landingPageContent');
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      // If cache is still valid, use it
      if (Date.now() - timestamp < CACHE_TTL) {
        return data;
      }
    }
    // Otherwise return initial state
    return {
      hero: { title: "", subtitle: "" },
      mainContent: {
        imageSrc: "/Peligoni-10.webp",
        paragraph1: "",
        paragraph2: "",
        quote: "",
      }
    };
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadContent = async () => {
    // Check if we need to fetch fresh content
    const cached = localStorage.getItem('landingPageContent');
    if (cached) {
      const { timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_TTL) {
        setLoading(false);
        return; // Use cached content
      }
    }

    try {
      const docRef = doc(db, "content", "landingPage");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const newContent = docSnap.data();
        setContent(newContent);
        // Cache the new content with timestamp
        localStorage.setItem('landingPageContent', JSON.stringify({
          data: newContent,
          timestamp: Date.now()
        }));
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  const saveContent = async (newContent) => {
    try {
      await setDoc(doc(db, "content", "landingPage"));
      setContent(newContent);
      // Update cache after successful save
      localStorage.setItem('landingPageContent', JSON.stringify({
        data: newContent,
        timestamp: Date.now()
      }));
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    }
  };

  return { content, setContent, saveContent, loading, error };
};