import React from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { ChevronRight, Facebook, Twitter, Instagram } from "lucide-react";
import SponsorsSection from "../Components/SponsorsSection";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const DecorativeSection = ({ children, className = "" }) => (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
    <div className={`relative ${className}`}>{children}</div>
  </div>
);

const HomePage = () => {
  const { scrollY } = useScroll();
  const backgroundY = useTransform(scrollY, [0, 1000], [0, 300]);
  const smoothBackgroundY = useSpring(backgroundY, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <div className="min-h-screen relative overflow-hidden">
      {/* Animated Background */}
      <motion.div
        className="absolute inset-0 -z-10"
        style={{
          y: smoothBackgroundY,
          background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
        }}
      />

      {/* Hero Section */}
      <section className="relative h-screen">
        <div className="absolute inset-0">
          <img
            src="/Peligoni-10.webp"
            alt="Chris Hoy Cycling"
            className="w-full h-full object-cover opacity-40"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-backgroundDark/60 to-backgroundDark" />
        </div>

        <div className="relative h-full flex flex-col items-center justify-center text-center px-4">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="max-w-4xl mx-auto space-y-6"
          >
            <h1 className="text-7xl font-bold text-white mb-6">
              TOUR DE <span style={{ color: colors.accent }}>4</span>
            </h1>
            <p className="text-3xl text-white/90 mb-8">
              Riding Together Against Stage 4 Cancer
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-4 rounded-full text-slate-800 font-semibold text-xl"
              style={{ background: colors.accent }}
            >
              Join The Movement
            </motion.button>
          </motion.div>
        </div>
      </section>

      {/* Sir Chris Hoy Introduction */}
      <section className="py-24 px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center"
        >
          <div className="relative">
            <img
              src="/Peligoni-10.webp"
              alt="Sir Chris Hoy"
              className="rounded-lg shadow-xl"
            />
          </div>
          <div className="space-y-6">
            <h2 className="text-4xl font-bold text-white">Sir Chris Hoy</h2>
            <p className="text-xl text-white/80 leading-relaxed">
              Following Sir Chris Hoy's diagnosis, Tour de 4 emerged as a
              powerful initiative to demonstrate that living well with Stage 4
              cancer is possible. As a six-time Olympic champion, Chris brings
              his fighting spirit to this new challenge.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              className="px-6 py-3 rounded-full text-white"
              style={{ background: colors.secondary }}
            >
              Read Chris's Story
            </motion.button>
          </div>
        </motion.div>
      </section>

      {/* Quick Facts */}

      <section className="py-24 px-4 bg-white/5">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="max-w-6xl mx-auto space-y-16"
        >
          {/* Stepped Underline */}
          <div className="relative">
            <h2 className="text-3xl font-bold text-white text-center">
              Stage 4 Cancer Awareness
            </h2>
            <div className="flex justify-center gap-1 mt-2">
              <div className="w-16 h-0.5 bg-secondary" />
              <div className="w-32 h-0.5 bg-accent" />
              <div className="w-16 h-0.5 bg-secondary" />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Living with Stage 4",
                stat: "32K+",
                desc: "People living with Stage 4 cancer in the UK",
              },
              {
                title: "Research Impact",
                stat: "£500M",
                desc: "Annual funding needed for Stage 4 research",
              },
              {
                title: "Community",
                stat: "1 in 2",
                desc: "People affected by cancer in their lifetime",
              },
            ].map((fact, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: i * 0.2 }}
                className="p-8 bg-white/10 backdrop-blur-sm rounded-lg text-center"
              >
                <h3 className="text-2xl font-bold text-white mb-2">
                  {fact.stat}
                </h3>
                <p className="text-lg font-semibold text-white/90 mb-4">
                  {fact.title}
                </p>
                <p className="text-white/70">{fact.desc}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Registration CTA */}
      <section className="bg-white py-24 px-4">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto text-center space-y-8"
        >
          <h2 className="text-4xl font-bold text-secondary">
            Be Part of Something Bigger
          </h2>
          <p className="text-xl text-primaryDark/80">
            Join us in this landmark cycling event to raise awareness and vital
            funds for Stage 4 cancer research.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              className="px-8 py-4 rounded-full text-slate-800 font-semibold"
              style={{ background: colors.accent }}
            >
              Register Interest
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              className="px-8 py-4 rounded-full text-white"
              style={{ background: colors.secondary }}
            >
              Sponsor The Event
            </motion.button>
          </div>
        </motion.div>
      </section>

      {/* Sponsors Banner */}

      <SponsorsSection />
      {/* <section className="py-16 px-4 bg-white/5">
        <div className="max-w-6xl mx-auto">
          <h3 className="text-2xl font-bold text-white text-center mb-12">
            Our Sponsors
          </h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center">
            {["zwift", "insights", "rapha", "logo4"].map((sponsor) => (
              <div
                key={sponsor}
                className="p-8 bg-white/10 rounded-lg flex items-center justify-center"
              >
                <div className="w-32 h-32 relative flex items-center justify-center">
                  <img
                    src={`/sponsors/${sponsor}.png`}
                    alt={`${sponsor} logo`}
                    className="max-w-full max-h-full object-contain opacity-70 hover:opacity-100 transition-opacity"
                    onError={(e) => {
                      if (e.target.src.endsWith(".png")) {
                        e.target.src = `/sponsors/${sponsor}.svg`;
                      } else if (e.target.src.endsWith(".svg")) {
                        e.target.src = `/sponsors/${sponsor}.jpg`;
                      } else if (e.target.src.endsWith(".jpg")) {
                        e.target.src = `/sponsors/${sponsor}.webp`;
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* Social Media Integration */}
      <section className="bg-white py-16 px-4">
        <div className="max-w-6xl mx-auto text-center space-y-8">
          <h3 className="text-2xl font-bold text-secondary">
            Follow Our Journey
          </h3>
          <div className="flex justify-center space-x-8">
            {[
              { Icon: Facebook, label: "Facebook" },
              { Icon: Twitter, label: "Twitter" },
              { Icon: Instagram, label: "Instagram" },
            ].map(({ Icon, label }) => (
              <motion.a
                key={label}
                href="#"
                whileHover={{ scale: 1.1 }}
                className="text-primary-light/80 hover:text-secondary transition-colors duration-300"
              >
                <Icon className="w-8 h-8" />
                <span className="sr-only">{label}</span>
              </motion.a>
            ))}
          </div>
        </div>
      </section>

      {/* News Ticker */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="fixed bottom-0 w-full bg-white/10 backdrop-blur-md py-4"
      >
        <div className="max-w-7xl mx-auto px-4 flex items-center space-x-8">
          <span className="text-white font-semibold whitespace-nowrap">
            Latest Updates:
          </span>
          <motion.div
            animate={{ x: ["100%", "-100%"] }}
            transition={{ duration: 30, repeat: Infinity, ease: "linear" }}
            className="text-white/80 whitespace-nowrap"
          >
            Registration opens next month • Training rides with Sir Chris Hoy
            announced • New corporate sponsors joining the cause • Community
            events being planned nationwide
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default HomePage;
